if(process.env.MIX_SENTRY_DSN && `${process.env.MIX_SENTRY_DSN}`!=='undefined' && `${process.env.MIX_SENTRY_DSN}`!=='' && !/Daum|Acunetix|HeadlessChrome|baiduboxapp|WebPageTest|bot| OPR\/\d+\.\d+| BW\/\d+\.\d+|compatible; Yeti\/\d+\.\d+| PTST\/\d+\.\d+/i.test(navigator.userAgent)) {
	let Sentry = require("@sentry/browser");
	//let { BrowserTracing } = require("@sentry/tracing");
	let { CaptureConsole, ExtraErrorData } = require("@sentry/integrations");


	let logs = ['info', 'warn', 'error', 'debug'];
	if (`${process.env.NODE_ENV}` === "production") {
		logs = ['error'];
	}
	Sentry.init({
		release: 'dev',
		environment: `${process.env.NODE_ENV}`,
		dsn: `${process.env.MIX_SENTRY_DSN}`,
		ignoreErrors: [
			'TurnJsError', 
			'The page 1 does not exist', 
			'turnError',
			/consoleLog/,
			/missing \) after argument list/,
			/\(evaluating 'e\.apply'/,
			/Failed to fetch/,
			/Non-Error promise rejection captured with value: undefined/,
			/docs\.js/,
			/_AutofillCallbackHandler/,
			/extension:\/\//,
			/webkit-masked-url:/,
			/msDiscoverChatAvailable/,
			/maps\/api/
		],
		denyUrls: [
			// Chrome extensions
			/extensions\//i,
			/^chrome:\/\//i,
		],
		integrations: [
			new Sentry.BrowserTracing(),
			new CaptureConsole(
				{
					// array of methods that should be captured
					// defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
					levels: logs
				}
			),
			new ExtraErrorData({
				// Limit of how deep the object serializer should go. Anything deeper than limit will
				// be replaced with standard Node.js REPL notation of [Object], [Array], [Function] or
				// a primitive value. Defaults to 3.
				depth: 5,
			}),
			//new Sentry.Replay()
		],
		// replaysSessionSampleRate: 0.01,
		// If the entire session is not sampled, use the below sample rate to sample
		// sessions when an error occurs.
		// replaysOnErrorSampleRate: 0.18,
		// We recommend adjusting this value in production, or using tracesSampler
		// for finer control
		sampleRate: 0.2,
		tracesSampleRate: 0.1
	});
	let uid = '';
	if (window.$ && $.cookie) {
		uid = $.cookie('_clck');
	} else {
		uid = (cname => {
			let name = cname + "=";
			let decodedCookie = decodeURIComponent(document.cookie);
			let ca = decodedCookie.split(';');
			for(let i = 0; i <ca.length; i++) {
				let c = ca[i];
				while (c.charAt(0) === ' ') {
					c = c.substring(1);
				}
				if (c.indexOf(name) === 0) {
					return c.substring(name.length, c.length);
				}
			}
			return "";
		})('_clck');
	}
	
	if(uid)
	{
		Sentry.setUser({ id: uid.split('|')[0] });
	}
}
if (!window.__gCrWeb) window['__gCrWeb'] = {};